.ids-card.ids-card-tier-1 {
  @apply rounded-none p-3 md:rounded-[.25rem] md:p-6;

  > ids-card-header.ids-card-header {
    @apply gap-1 pb-2 md:gap-3 md:pb-4;

    .ids-badge {
      @apply p-2 md:h-16 md:w-16 md:p-4;
    }

    .ids-icon {
      @apply h-4 w-4 md:h-8 md:w-8;
    }

    .ids-card-title {
      @apply text-lg font-medium md:text-4xl;
    }

    .ids-card-subtitle {
      @apply text-text-tertiary text-base font-medium leading-[1.125rem] md:text-3xl;
    }
  }

  > .ids-card-content {
    @apply flex flex-col gap-3 pt-3 md:gap-6 md:pt-6;
  }

  > .ids-card-footer {
    @apply mt-3 flex justify-center md:mt-6;
  }
}

.ids-card.ids-card-tier-2 {
  @apply p-3 pt-2 md:p-6 md:pt-3;

  > ids-card-header.ids-card-header {
    .ids-card-title {
      @apply md:text-3xl;
    }

    .ids-card-subtitle {
      @apply text-text-tertiary text-base md:text-2xl;
    }
  }

  .ids-card-content {
    @apply mt-3 flex flex-col items-start gap-6 text-xl;
  }

  .ids-card-footer {
    @apply flex flex-col items-center gap-6 p-6;
  }
}

.ids-card.ids-card-tier-3 {
  @apply bg-bg-light p-2 md:p-3;
}
