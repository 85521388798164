ids-card-content.ids-card-content {
  .form-control {
    @apply w-full;

    label {
      @apply text-text-tertiary mb-1 flex text-sm md:text-xl md:leading-[24px];
    }

    input,
    input.ids-form-control {
      @apply border-border-medium focus:border-primary flex h-8 w-full flex-col rounded border border-solid px-2 py-[6px] text-base leading-[1.125rem] md:h-[unset] md:text-2xl;
    }

    svg.fa-calendar-alt {
      @apply text-primary md:h-6 md:w-6;
      left: calc(100% - 2rem);
      top: calc(50% - 14px);

      &:hover {
        @apply cursor-pointer;
      }
    }
  }
}
