@import 'lib/tailwind.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'index.scss';

// kiosk theme overrides
@import 'assets/ids-general-overrides';
@import 'assets/ids-card-overrides';
@import 'assets/ids-form-field-overrides';
@import 'assets/ids-notifications-overrides';

@import 'tailwindcss/components';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  @apply sm:bg-bg-cool bg-white;
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-left);
}

body {
  @apply text-text-primary-light bg-bg-cool overflow-visible text-base;
  font-family: Inter, sans-serif;
}

/* hide the counter buttons on number input fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

@layer components {
  .header-drop-shadow-light {
    box-shadow:
      0px 1.5px 3.5px 0px rgba(0, 0, 0, 0.141),
      0px 0.4px 1px 0px rgba(0, 0, 0, 0.2);
  }

  .footer-drop-shadow-light {
    box-shadow:
      0px -1.5px 3.5px 0px rgba(0, 0, 0, 0.141),
      0px -0.4px 1px 0px rgba(0, 0, 0, 0.2);
  }
}

small {
  @apply text-sm md:text-base;
}
