@tailwind base;

/* Main colors */
@layer base {
  :root {
    /* Unbound Colors */
    --white: #ffffff;
    --light-blue: #00aacf;
    --accent-blue: #109cf1;
    --blue-30: #41aceb;
    --blue-40: #76a9ff;
    --blue-80: #00609d;
    --blue-90: #1169a0;
    --blue-100: #1a83c5;
    --yellow: #ffb946;
    --red: #f7685b;
    --red-10: #fddcda;
    --red-30: #faa9a3;
    --green: #2ed47a;
    --purple: #885af8;
    --black: #192a3e;
    --table-black: #323c47;
    --table-gray: #707683;
    --dark-blue-text: #334d6e;
    --gray: #90a0b7;
    --gray-4: #ced4da;
    --gray-6: #787885;
    --gray-8: #33383e;
    --gray-10: #e1e1e3;
    --gray-30: #b4b4bb;
    --gray-40: #9696a0;
    --gray-100: #33383e;
    --icon-gray: #c2cfe0;
    --white-40: #f5f6f8;
    --white-50: #e9ecef;
    --yellow-10: #cadc4f;
    --alpha-2: #00000033;
    --alpha-3: #0000004d;
    --alpha-4: #00000066;
    --alpha-5: #00000080;
    --alpha-6: #00000099;
    --alpha-7: #000000b3;
    --alpha-8: #000000cc;
    --alpha-9: #000000e6;

    /* Primary */
    --color-scheme-primary: #0068de;
    --color-scheme-primary-hover: #0059bd;
    --color-scheme-primary-pressed: #00499c;
    --color-scheme-primary-bg-light: #e5f0fc;
    --color-scheme-primary-bg-dark: #bfd9f7;

    /* Call to Action */
    --color-scheme-confirm: #21ba63;
    --color-scheme-confirm-hover: #1c9e54;
    --color-scheme-confirm-pressed: #178345;
    --color-scheme-confirm-bg-light: #38f8ef;
    --color-scheme-confirm-bg-dark: #a6e3c1;

    /* Alert | Warning | Time Sensitive */
    --color-scheme-alert: #ff7b31;
    --color-scheme-alert-bg-light: #fff2ea;
    --color-scheme-alert-bg-dark: #ffb083;

    /* Destructive | Error | Critical */
    --color-scheme-critical: #e13137;
    --color-scheme-critical-hover: #bf2a2f;
    --color-scheme-critical-pressed: #9e2227;
    --color-scheme-critical-shadow: #d2231b;
    --color-scheme-critical-bg-light: #fceaeb;
    --color-scheme-critical-bg-dark: #f7cbcd;

    /* Text Colors ================================================================ */

    --color-scheme-text-primary: #141414;
    --color-scheme-text-secondary: #343537;
    --color-scheme-text-tertiary: #6d7284;

    --color-scheme-text-dark-primary: #ffffff;
    --color-scheme-text-dark-secondary: #acb2c8;

    /* UI Elements ================================================================ */

    /* Backgrounds */
    --color-scheme-bg-cool: #eaecf4;
    --color-scheme-bg-light: #f5f6f8;
    --color-scheme-bg-pure: #fff;

    /* Border (Stroke/Outline) */
    --color-scheme-border-light: #ced4da;
    --color-scheme-border-medium: #acb2c8;

    /* Neutral Elements */
    --color-scheme-ui-element-light: #eaecf4;
    --color-scheme-ui-element-disabled: #acb2c8;
    --color-scheme-ui-element-medium: #6d7274;

    /* Nav Menu Colors */
    --color-scheme-menu-primary: #141a2f;
    --color-scheme-menu-secondary: #283052;
    --color-scheme-menu-tertiary: #3a4775;
    --color-scheme-menu-nav-category-label: #acb2c8;

    /* Alerts */
    --color-scheme-alerts: #ff7b31;
    --color-scheme-alerts-bg-light: #fff2ea;
    --color-scheme-alerts-bg-dark: #ffb083;

    /* Patient Info */
    --color-scheme-patient: #ffab2d;
    --color-scheme-patient-bg-light: #fff6ea;
    --color-scheme-patient-bg-dark: #ffddab;

    /* Financial */
    --color-scheme-financial: #21ba63;
    --color-scheme-financial-bg-light: #e8f8ef;
    --color-scheme-financial-bg-dark: #a6e3c1;

    /* Contacts */
    --color-scheme-contacts: #00c6c2;
    --color-scheme-contacts-bg-light: #e5f9f9;
    --color-scheme-contacts-bg-dark: #99e8e7;

    /* Visit Info */
    --color-scheme-visit: #3633e3;
    --color-scheme-visit-bg-light: #ebeafc;
    --color-scheme-visit-bg-dark: #afadf4;

    /* Communication */
    --color-scheme-communication: #af39d9;
    --color-scheme-communication-bg-light: #f7ebfb;
    --color-scheme-communication-bg-dark: #dfb0f0;

    /* Women's health */
    --color-scheme-womens-health: #e85099;
    --color-scheme-womens-health-bg-light: #fdedf5;
    --color-scheme-womens-health-bg-dark: #f6b9d6;
  }
}
