// Toasts
.ids-adc-toast-container.adc-toast {
  @apply mt-9 md:mt-32;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.ids-badge.ids-badge-icon {
  @apply p-2;
}

// Dialogs
.cdk-global-overlay-wrapper .cdk-overlay-pane.mat-mdc-dialog-panel {
  @apply max-w-[90vw] md:max-w-[80vw];
}
